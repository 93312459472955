// eslint errors.
/*  eslint-disable no-param-reassign */
/* eslint-disable sonarjs/cognitive-complexity */

import React from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from 'react-intl';
import HeaderText from '@UI/HeaderText';
import BlogFilter from '@Filters/Blog';
import SortBar from '@Components/SortBar';
import Pager from '@Components/Pager';
import Breadcrumbs from '@Components/Breadcrumbs';
import NoResults from '@Components/NoResults';
import Block from '@UI/Block';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import generateDataLayer from 'dataLayerHelper';
import searchParamsStringify from '@Utils/searchParamsStringify';
import GetRouteFromUrl from 'GetRouteFromUrl';
import ArticleList from '@UI/ArticleOverview/List';
import { getCurrentCategoryName } from 'facetsHelper';
import withSearch from '../../components/hoc/Search';

function Cleaner({
  intl, searchParams, pager, articles, total, canonicalOverrides, staticContext,
}) {
  const { locale } = intl;
  const route = useRouteMatch();
  const routeConfig = GetRouteFromUrl();
  const baseUrl = route.path.replace(':searchParams*', '');
  if (searchParams && searchParams.page && parseInt(searchParams.page) === 1) {
    return (
      <Route render={({ theStaticContext }) => {
        if (theStaticContext) theStaticContext.status = 301;
        return <Redirect to={`${baseUrl}${searchParamsStringify('cleaner', { ...searchParams, ...{ page: null } })}`} />;
      }}
      />
    );
  }
  if (searchParams && searchParams.unknown) {
    return (
      <Route render={({ theStaticContext }) => {
        if (theStaticContext) theStaticContext.status = 301;
        return <Redirect to={baseUrl} />;
      }}
      />
    );
  }

  // Pass the dataLayer to the server using staticContext.
  const trackingSettings = 'rbd_tracking.settings';
  if (staticContext && staticContext.data && staticContext.appConfigurations
    && Object.prototype.hasOwnProperty.call(staticContext.appConfigurations, trackingSettings)
    && staticContext.appConfigurations[trackingSettings].gtm
    && staticContext.appConfigurations[trackingSettings].gtm.enabled) {
    staticContext.dataLayer = generateDataLayer('other_content', locale);
  }
  let metaTitle = intl.formatMessage({ id: 'Cleaner.Title' }, { sitename: process.env.REACT_APP_SITENAME });
  let metaDescription = intl.messages['Cleaner.MetaDescription'] ? intl.messages['Cleaner.MetaDescription'] : null;
  let titleTop = intl.formatMessage({ id: 'CleanerHeader.TitleTop' });
  let titleBottom = intl.messages['CleanerHeader.TitleBottom'] ? intl.formatMessage({ id: 'CleanerHeader.TitleBottom' }) : null;

  const categoryName = searchParams.facets ? getCurrentCategoryName(searchParams.facets) : '';
  if (categoryName.length) {
    metaTitle = intl.messages['Cleaner.CategoryTitle'] ? intl.formatMessage({ id: 'Cleaner.CategoryTitle' }, {
      sitename: process.env.REACT_APP_SITENAME,
      category: categoryName,
    }) : metaTitle;
    metaDescription = intl.messages['Cleaner.CategoryMetaDescriptionTitle'] ? intl.formatMessage({ id: 'Cleaner.CategoryMetaDescriptionTitle' }, {
      sitename: process.env.REACT_APP_SITENAME,
      category: categoryName,
    }) : metaDescription;
    titleTop = intl.messages['CleanerHeader.CategoryTitleTop'] ? intl.formatMessage({ id: 'CleanerHeader.CategoryTitleTop' }, { category: categoryName }) : titleTop;
    titleBottom = intl.messages['CleanerHeader.CategoryTitleTopBottom'] ? intl.formatMessage({ id: 'CleanerHeader.CategoryTitleTopBottom' }) : titleBottom;
  }
  metaTitle = metaTitle.charAt(0).toUpperCase() + metaTitle.slice(1);
  if (searchParams.page && intl.messages['Global.Page']) {
    metaTitle = `${intl.formatMessage({ id: 'Global.Page' }, { page: searchParams.page })} ${metaTitle}`;
  }

  const searchParamsString = searchParamsStringify('cleaner', searchParams);
  let canonicalLink = baseUrl + searchParamsString;
  // Page's canonical link, can be overridden,
  // from CMS configuration.
  if (canonicalOverrides && Object.keys(canonicalOverrides).length > 0) {
    let overrideValue;
    if (canonicalOverrides[canonicalLink]) {
      // First check for full match.
      overrideValue = canonicalOverrides[canonicalLink] ? canonicalOverrides[canonicalLink] : '';
    } else if (searchParams && searchParams.page) {
      const noPager = baseUrl + searchParamsStringify('cleaner', searchParams);
      if (canonicalOverrides[noPager]) {
        const baseOverrideValue = canonicalOverrides[noPager];
        overrideValue = canonicalLink.replace(noPager, baseOverrideValue);
      }
    }
    if (canonicalOverrides[baseUrl] && overrideValue === undefined) {
      // Try to apply wildcard, for base path override.
      overrideValue = canonicalOverrides[baseUrl] + searchParamsString;
    }
    if (overrideValue && overrideValue.length > 1) canonicalLink = overrideValue;
  }

  return (
    <div id="articles-search-listing">
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}${canonicalLink}`} />
      </Helmet>
      <Breadcrumbs breadcrumbs={routeConfig.breadcrumbs} currentRoute={route} searchParams={searchParams} pageType="press" />
      <HeaderText
        bgColor="blue"
        titleTop={titleTop}
        titleBottom={titleBottom}
      >
        {intl.messages['CleanerHeader.text'] && intl.formatMessage({ id: 'CleanerHeader.text' })}
      </HeaderText>
      <SortBar resultsTotal={total} searchParams={searchParams} />
      <Block beforeContent={<BlogFilter pageType="press" searchParams={searchParams} resultsTotal={total} />} align="right" contentSize="s" type="filter">
        <ArticleList>
          {total > 0 ? articles : <NoResults />}
        </ArticleList>
        <Pager previous={pager.previous} pagesList={pager.pages} next={pager.next} />
      </Block>
    </div>
  );
}

export default injectIntl(withSearch(Cleaner));
